(function($) {


    var productSearch = $('#filtersSearch_js');
//    var searchForm = productSearch.find('form');
    var submit_search = $('#submit_search');

    //filter ajax
    $("#filterPrice_js .icon").on('click', function() {
        $value = $(this).data( "price");

        if( $(this).hasClass('active')){
            $("#filterPrice_js .icon").removeClass('active hightlight');
        }else{
            $("#filterPrice_js .icon").removeClass('active hightlight');
            $(this).addClass('active');
            $('#filterPrice_js .icon:nth-child(-n+' + $value +')' ).addClass('hightlight');
        }


    });

    $("#filterSecurity_js .icon").on('click', function() {
        $value = $(this).data( "security");

        if( $(this).hasClass('active')){
            $("#filterSecurity_js .icon").removeClass('active hightlight');
        }else{
            $("#filterSecurity_js .icon").removeClass('active hightlight');
            $(this).addClass('active');
            $('#filterSecurity_js .icon:nth-child(-n+' + $value +')' ).addClass('hightlight');
        }

    });


    //get param url and fake click on button
    $.urlParam = function(name){
        var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
        if (results==null){
            return null;
        }
        else{
            return decodeURI(results[1]) || 0;
        }
    }


    var getLigne = $.urlParam('ligne'); 
    var getMateriau =  $.urlParam('materiau');      


    if( getLigne != 0 ){ 
        $("#filterLignes_js input[type=checkbox][value=" + getLigne +"]").prop("checked",true);
    }

    if( getMateriau != 0 ){ 
        $("#filterMateriaux_js input[type=checkbox][value=" + getMateriau +"]").prop("checked",true);
    }

    if (  getLigne != 0  || getMateriau != 0){
        filtersSearch('submitFilters');
    }


    $("#load_more_js button").on('click', function(e) {
        e.preventDefault();
        filtersSearch('loadMore');
        $('#load_more_js').remove();
    });

    submit_search.on('click', function(e) {
        e.preventDefault();
        submit_search.val('valider');
        filtersSearch('submitFilters');
       
 
    });


    // all the time we click on checkbox
    $('#filtersSearch_js input[type=checkbox], #filtersSearch_js .icon').on('click', function(e) {
        submit_search.prop("disabled", false);
        calculResults();
     
    });


    function calculResults(){                

        //materiaux
        var materiauxCheckbox = new Array();
        $("#filterMateriaux_js input:checked").each(function() {
            materiauxCheckbox.push($(this).val());
        });

        //ligne
        var lignesCheckbox = new Array();
        $("#filterLignes_js input:checked").each(function() {
            lignesCheckbox.push($(this).val());
        });

        //model
        var filterModeles = new Array();
        $("#filterModeles_js input:checked").each(function() {
            filterModeles.push($(this).val());
        });

        // price
        var filterPrice = new Array();
        $("#filterPrice_js .icon.active").each(function() {
            filterPrice.push($(this).data( "price"));                  
        });

        // security
        var filterSecurity = new Array();
        $("#filterSecurity_js .icon.active").each(function() {
            filterSecurity.push($(this).data( "security"));  
        });


        var search = $('#filterFreeSearch_js input').val();


        //send data
        var data = { 
            action: 'calcul_results',
            materiau: materiauxCheckbox,
            ligne: lignesCheckbox,
            modele: filterModeles,
            price : filterPrice,
            search: search,
            security : filterSecurity
        };


      

        $.ajax({
            url: ajax_url,
            type : 'POST',
            data: data,
            success: function(data){

                console.log(data.data);
                if(data.data && data.data !== 0){ 
                    // on affiche les résultats 
                    submit_search.prop("disabled", false).val('voir les ' + data.data + ' résultats');
                 
                }else{
                    submit_search.attr('disabled', 'disabled').val("Aucun résultat");
              
              

                }
            },
            // La fonction à appeler si la requête n'a pas abouti
            error: function() {
                // J'affiche un message d'erreur
                submit_search.val("Erreur");
            }
        });
    }





    function filtersSearch(type){                

        //materiaux
        var materiauxCheckbox = new Array();
        $("#filterMateriaux_js input:checked").each(function() {
            materiauxCheckbox.push($(this).val());
        });

        //ligne
        var lignesCheckbox = new Array();
        $("#filterLignes_js input:checked").each(function() {
            lignesCheckbox.push($(this).val());
        });

        //model
        var filterModeles = new Array();
        $("#filterModeles_js input:checked").each(function() {
            filterModeles.push($(this).val());
        });

        // price
        var filterPrice = new Array();
        $("#filterPrice_js .icon.active").each(function() {
            filterPrice.push($(this).data( "price"));                  
        });

        // security
        var filterSecurity = new Array();
        $("#filterSecurity_js .icon.active").each(function() {
            filterSecurity.push($(this).data( "security"));  
        });


        var search = $('#filterFreeSearch_js input').val();


        var numberPage =  $('#number_page_js').val();
        if( type == "submitFilters"){ // au clicks sur btn valider 
            $('#number_page_js').val(1);
            numberPage = 1;
        }else{ // au load more 
            numberPage++;
            $('#number_page_js').val(numberPage);
        }



        //send data
        var data = { 
            action: 'product_search',
            materiau: materiauxCheckbox,
            ligne: lignesCheckbox,
            modele: filterModeles,
            price : filterPrice,
            security : filterSecurity, 
            search: search,
            page: numberPage,
            type: type
        };


        $.ajax({
            url: ajax_url,
            type : 'POST', 
            data: data,
            dataType: 'html',
            success: function(response){

                // on affiche les résultats 
                if( type == "submitFilters"){ // au clicks sur btn valider 
                    submit_search.prop("disabled", false)
                    $('#container_loadResult_js').html(response); 
                }else{ // au load more 
                    $('#container_loadResult_js').append(response);  // semble ok
                }

                $("#load_more_js button").on('click', function(e) {
                    e.preventDefault();
                    filtersSearch('loadMore');
                    $('#load_more_js').remove();
                });

            }
        });
    }




})(jQuery);




